import { useEffect, useState } from "react"
import servicesUtils from "../utils/servicesUtils"
import { format, parseISO } from "date-fns"
import classNames from "classnames"
import Image from "next/image"
import { useStoreState } from "pullstate"
import Store from "../utils/Store"
import useWebsocket from "../hooks/useWebsocket"
import { get, isEmpty } from "lodash"
import { getGender } from "../utils"
import axios from "axios"

const COLUMNS = [
  "created_date",
  "patient_details",
  "priority",
  "identifier",
  "status"
]

const LABELS = {
  "created_date": "Date",
  "patient_details": "Patient Identifier",
  "priority": "Priority",
  "identifier": "Accession Number",
  "status": "Status"
}

const DEFAULT_SORT_ORDER = [
  {
    name: "priority",
    isAscending: false
  },
  {
    name: "created_date",
    isAscending: false
  }
]

function NhsxStudy() {

  const [isLoading, setIsLoading] = useState(true)
  const [imageSeries, setImageSeries] = useState([])
  const [copiedIdentifier, setCopiedIdentifier] = useState({
    id: null,
    text: ""
  })
  const [currentColumnSort, setCurrentColumnSort] = useState({
    order: DEFAULT_SORT_ORDER
  })

  const [readPatientsCount, setReadPatientsCount] = useState()

  const { user } = useStoreState(Store, (s) => ({
    user: s.user,
  }));
  const userId = get(user, ["sub"], "");
  const { socket, wsData } = useWebsocket(`/nhsx_study/${userId}/`, !isEmpty(user));

  useEffect(() => {
    if (window) {
      if (window.Notification) {
        Notification.requestPermission()
      }
    }
  }, [window])

  useEffect(() => {

    const getImageSeries = async () => {
      const response = await servicesUtils.getService('/nhsx_study/')

      const nhsxImageSeries = await Promise.all(response.map(async (nhsxImageSeries) => {
        return  getPatientDetails(nhsxImageSeries);
      }));

      nhsxImageSeries.forEach(nhsxImageSeries => {
        const { series_identifier, findings } = nhsxImageSeries
        const aiInference = series_identifier?.ai_inference
        const priority = getPriority(findings, aiInference)
        nhsxImageSeries['priority'] = priority
      })
      setImageSeries(nhsxImageSeries)
      setReadPatientsCount(nhsxImageSeries.length)
      setIsLoading(false)
    }

    getImageSeries()
  }, [])

  useEffect(() => {
    if (window) {
      window.nhsxElectronAPI?.setUnreadPatients(async (event, args) => {
        const response = await servicesUtils.getService('/nhsx_study/')
        setReadPatientsCount(response.length)
      })
    }
  }, [window])

  useEffect(() => {
    if (wsData) {
      if (wsData.action === "nhsx_new_image_series_added") {
        getPatientDetails(wsData.data).then(data => {
          const { series_identifier, findings } = data
          const aiInference = series_identifier?.ai_inference
          const priority = getPriority(findings, aiInference)
          data['priority'] = priority

          const [updateImageSeries, isPresent] = insertOrUpdateObject(imageSeries, data)
          window.nhsxElectronAPI?.setAppBadgeNumber(updateImageSeries.length - readPatientsCount)
          setImageSeries(updateImageSeries)

          if (priority == 5) {
            new Notification("Qure.ai", {
              body: "Priority 5 patient",
              icon: "/assets/icons/appIcons/icon-128x128.png"
            })
          }

          //if (priority == 5) {
          //  window?.nhsxElectronAPI.sendNotification(JSON.stringify(data))
          //}
        })
      }
    }
  }, [wsData]);

  function customSortImageSeries(keys) {
    return function (a, b) {
      for (const key of keys) {
        const { name, isAscending } = key

        if (isAscending) {
          if (a[name] === b[name]) {
            continue;
          } else if (a[name] < b[name]) {
            return -1
          } else if (a[name] > b[name]) {
            return 1
          }
        } else {
          if (a[name] === b[name]) {
            continue;
          } else if (a[name] < b[name]) {
            return 1
          } else if (a[name] > b[name]) {
            return -1
          }
        }
      }
      return 0;
    };
  }
  imageSeries.sort(customSortImageSeries(currentColumnSort.order))

  //  const reviewedNhsxSeries = imageSeries.filter(imgSer => imgSer.status === 2)
  //  const unreviewedNhsxSeries = imageSeries.filter(imgSer => imgSer.status !== 2)

  //  let imageSeries_
  //  imageSeries_ = [...unreviewedNhsxSeries, ...reviewedNhsxSeries]

  console.log({imageSeries})
  return (
    <div className="text-gray-100 border-b-[1px] border-b-gray-600 h-screen flex flex-col pb-6">

      <div className="flex items-center justify-between px-5 py-3">
        <span className="text-lg font-bold flex space-x-3">
          <Image
            src={"/assets/images/qureLogo.svg"}
            className="rounded"
            alt="qure-logo"
            width="132"
            height="44"
          />
          <span className="flex items-center">Qure's Priority List</span>
        </span>

        <span className="flex text-center justify-center space-x-5">
          <span className="flex items-center">
            <button className="px-5 py-2 bg-gray-700 hover:bg-gray-800 rounded-6" onClick={() => {
              Store.update((s) => {
                s.isLogoutOpen = true;
              })
            }}>Logout</button>
          </span>
        </span>
      </div>

      <div className="flex flex-col grow">
        <div className="grid grid-cols-10 bg-gray-800 text-gray-400 uppercase font-semibold text-center py-2">
          {
            COLUMNS.map(column => {
              const isSortable = DEFAULT_SORT_ORDER.map(key => key.name).indexOf(column) > -1
              return (
                <div
                  key={column} className={classNames("col-span-2 text-[12px] flex items-center justify-center space-x-3", {
                    "cursor-pointer hover:text-gray-200": isSortable
                  })}
                  onClick={() => {
                    if (isSortable) {
                      const order = currentColumnSort.order
                      const index = order.map(key => key.name).indexOf(column)

                      const currentIsAscending = order[index].isAscending
                      order.splice(index, 1);
                      order.unshift({
                        name: column,
                        isAscending: !currentIsAscending
                      });
                      setCurrentColumnSort({
                        order
                      })
                    }
                  }}
                >
                  <span>{LABELS[column] || column}</span>
                  {isSortable && (
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
                      </svg>
                    </span>
                  )}
                </div>
              )
            })
          }
        </div>

        <div className="divide-y-[1px] divide-gray-600 text-center h-[1px] grow overflow-y-auto scrollbar-light overflow-x-hidden">
          {isLoading ? (
            <div className="h-full w-full flex items-center justify-center text-gray-100">
              loading...
            </div>
          ) : (
            <>
              {
                imageSeries.map((singleImageSeries, index) => {
                  const { id, created_date, series_identifier, status, findings, patient } = singleImageSeries
                  const identifier = series_identifier?.accessionNumber
                  const isFindingsGt2 = findings?.length > 2
                  const aiInference = series_identifier?.ai_inference
                  const priority = getPriority(findings, aiInference)

                  return (
                    <div className={classNames("relative grid grid-cols-10 py-5 hover:bg-gray-700", {
                      "!border-b-[1px] border-b-gray-600": index === imageSeries.length - 1
                    })} key={id}>
                      <div className="col-span-2 flex items-center mx-auto text-[14px]">
                        {format(parseISO(created_date), "dd.MM.yy")} | {format(parseISO(created_date), "HH:mm")}
                      </div>

                      <div className="col-span-2 flex items-center justify-center text-[12px]">
                        <div className="text-left w-1/2">
                          <div>{patient?.name}</div>
                          <div>{patient?.age}Y {patient?.isDcmioPatientData ? patient.sex && "| " + patient.sex : getGender(patient?.gender)?.shortLabel && "| " + getGender(patient?.gender)?.shortLabel}</div>
                        </div>
                      </div>

                      <div className="col-span-2 grid grid-cols-12">
                        {
                          aiInference == 0 || aiInference == 1 ? (
                            <div className="col-span-12 flex items-center justify-center space-x-3">
                              {/*<div className="animate-spin h-[15px] w-[15px] border-2 border-dotted rounded-full"></div>
                            <div>
                              Processing...
                            </div>*/}
                            </div>
                          ) : (
                            <>
                              <span className={classNames("text-[12px] px-4 bg-gray-600 rounded-full py-2 col-span-6 h-max w-max mx-auto my-auto", {
                                "bg-red-500": priority === 5,
                                "bg-yellow-500": priority === 4,
                                "bg-yellow-600": priority === 3,
                                "bg-yellow-700": priority === 2,
                                "bg-yellow-800": priority === 1,
                                "bg-yellow-900": priority === 0,
                              })}>
                                {"Priority " + priority}
                              </span>
                              <span className="flex items-center flex-wrap gap-2 col-span-6 justify-start">
                                {
                                  findings && findings.slice(0, 2).map((finding, index) => {
                                    const isSecondFinding = index === 1
                                    return (
                                      <span key={finding} className={classNames("text-[10px] bg-gray-800 rounded-full col-span-3 flex items-center h-[20px]")}>
                                        <span className={classNames("mx-3", {
                                          "mr-1": isFindingsGt2 && isSecondFinding,
                                        })}>{finding}</span>
                                        {isFindingsGt2 && isSecondFinding && (
                                          <span className="group cursor-pointer relative rounded-full flex items-center justify-center h-[20px] w-[20px] border box-content border-gray-900 bg-gray-600">
                                            <span className="font-semibold text-base">
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                              </svg>
                                            </span>
                                            <div className="hidden group-hover:flex flex-col absolute z-[99999] translate-y-full">
                                              <div className="w-0 h-0 border-r-[5px] border-r-transparent border-b-[5px] border-b-gray-600 border-l-[5px] border-l-transparent mx-auto"></div>
                                              <div className="bg-gray-600 p-3 gap-2 flex items-center rounded-8">
                                                {
                                                  findings && findings.slice(2,).map(finding => {
                                                    return (
                                                      <span key={finding} className={classNames("text-[10px] bg-gray-800 px-3 rounded-full py-1 col-span-3 h-max")}>{finding}</span>
                                                    )
                                                  })
                                                }
                                              </div>
                                            </div>
                                          </span>
                                        )}
                                      </span>
                                    )
                                  })
                                }
                              </span>
                            </>
                          )
                        }
                      </div>

                      <div className="col-span-2 flex items-center justify-center">
                        <div className="space-x-3 text-[14px] px-5 py-2 rounded-6 bg-gray-600 w-2/3 flex items-center justify-between cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(identifier)
                            setCopiedIdentifier({
                              id,
                              text: identifier
                            })
                            setTimeout(() => {
                              setCopiedIdentifier({
                                id: null,
                                text: "null"
                              })
                            }, 1000);
                          }}
                          title={identifier}
                        >
                          <span className="truncate text-gray-200">{identifier}</span>
                          <span>

                            {
                              copiedIdentifier.id === id ? (
                                <span className="text-green-500">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                  </svg>
                                </span>
                              ) : (
                                <span className="text-gray-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                                    />

                                  </svg>
                                </span>

                              )
                            }

                          </span>
                        </div>
                      </div>

                      <div className="col-span-2 flex items-center px-8 z-[999]">
                        <button className={classNames("rounded flex items-center space-x-5")} onClick={() => updateStatus(id, status === 2 ? 1 : 2, setImageSeries, setIsLoading)}>
                          {
                            status == 2 ? (
                              <>
                                <span>
                                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.90137 1C2.79824 1 1.90137 1.89688 1.90137 3V13C1.90137 14.1031 2.79824 15 3.90137 15H13.9014C15.0045 15 15.9014 14.1031 15.9014 13V3C15.9014 1.89688 15.0045 1 13.9014 1H3.90137ZM12.4326 6.53125L8.43262 10.5312C8.13887 10.825 7.66387 10.825 7.37324 10.5312L5.37324 8.53125C5.07949 8.2375 5.07949 7.7625 5.37324 7.47188C5.66699 7.18125 6.14199 7.17813 6.43262 7.47188L7.90137 8.94063L11.3701 5.46875C11.6639 5.175 12.1389 5.175 12.4295 5.46875C12.7201 5.7625 12.7232 6.2375 12.4295 6.52813L12.4326 6.53125Z" fill="#00A9A7" />
                                    <path d="M12.4326 6.53125L8.43262 10.5312C8.13887 10.825 7.66387 10.825 7.37324 10.5312L5.37324 8.53125C5.07949 8.2375 5.07949 7.7625 5.37324 7.47188C5.66699 7.18125 6.14199 7.17813 6.43262 7.47188L7.90137 8.94063L11.3701 5.46875C11.6639 5.175 12.1389 5.175 12.4295 5.46875C12.7201 5.7625 12.7232 6.2375 12.4295 6.52813L12.4326 6.53125Z" fill="#0C1723" />
                                  </svg>
                                </span>
                                <span>Reviewed</span>
                              </>
                            ) : (
                              <>
                                <span>
                                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.90137 3C1.90137 1.89531 2.79668 1 3.90137 1H13.9014C15.0045 1 15.9014 1.89531 15.9014 3V13C15.9014 14.1031 15.0045 15 13.9014 15H3.90137C2.79668 15 1.90137 14.1031 1.90137 13V3ZM3.40137 3V13C3.40137 13.275 3.62512 13.5 3.90137 13.5H13.9014C14.1764 13.5 14.4014 13.275 14.4014 13V3C14.4014 2.72375 14.1764 2.5 13.9014 2.5H3.90137C3.62512 2.5 3.40137 2.72375 3.40137 3Z" fill="#9EA3A8" />
                                  </svg>
                                </span>
                                <span>Mark as Reviewed</span>
                              </>
                            )
                          }
                        </button>
                      </div>

                      {status === 2 && (
                        <div className="absolute opacity-70 bg-gray-900 top-0 left-0 w-full h-full"></div>
                      )}
                    </div>
                  )
                })
              }
            </>
          )
          }
        </div>
      </div>

    </div>
  )
}

async function updateStatus(id, updated_status, setImageSeries, setIsLoading) {
  setIsLoading(true)
  const response = await servicesUtils.postService('/nhsx_study/update_status/', {
    id: id,
    updated_status,
  })
  const nhsxImageSeries = await Promise.all(response.map(async (nhsxImageSeries) => {
    return  getPatientDetails(nhsxImageSeries);
  }));
  setImageSeries(nhsxImageSeries)
  setIsLoading(false)
}

// if present then returns true else false
function insertOrUpdateObject(arr, newObj) {
  const index = arr.findIndex(obj => obj.id === newObj.id);
  const newArray = [...arr];

  const isPresent = index !== -1
  if (isPresent) {
    newArray[index] = newObj;
  } else {
    newArray.push(newObj);
  }
  return [newArray, isPresent ? true : false];
}

export async function getStaticProps(context) {
  return {
    props: {
      hideMenu: true,
    },
  };
}

const aiInferenceForNotProcessed = [0,1]
const priority5Findings = ["ich", "fracture", "mls", "me"]

function getPriority(findings, aiInference) {
  try {
    if (aiInferenceForNotProcessed.includes(aiInference)) {
      return 0
    }
    if (findings.length === 0) {
      return 1
    }
    if (findings.some(finding => priority5Findings.includes(finding.toLowerCase()))) {
      return 5
    } else {
      return 3
    }
  } catch (error) {
    return 0
  }
}

function getFormattedTodaysDate() {
  const today = new Date();
  const day = today.getDate();
  const monthIndex = today.getMonth();
  const year = today.getFullYear();

  // Array of month names
  const monthNames = [
    "January", "February", "March",
    "April", "May", "June",
    "July", "August", "September",
    "October", "November", "December"
  ];

  // Suffix for the day (e.g., 1st, 2nd, 3rd, 4th)
  const suffix = (day > 3 && day < 21) || (day > 23 && day < 31) ? 'th' : ['st', 'nd', 'rd'][day % 10 - 1] || 'th';

  // Formatted date string
  const formattedDate = day + suffix + ' ' + monthNames[monthIndex] + ' ' + year;

  return formattedDate;
}

async function getPatientDetails(nhsxImageSeries) {
  try {
    const dcmioUrl = process.env.NEXT_PUBLIC_DCMIO_URL
    const seriesInstanceUID = nhsxImageSeries.series_identifier.seriesInstanceUID
    const url = dcmioUrl + "/nhsx-patient-details/" + seriesInstanceUID + "/"
    const response = await axios.get(url, {
      timeout: 1000
    })
    return {
      ...nhsxImageSeries,
      patient: response.data,
      isDcmioPatientData: true,
    }
  } catch (error) {
    return {
      ...nhsxImageSeries
    }
  }
}

export default NhsxStudy
